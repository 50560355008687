.channels-container {
  padding: 20px 0;
  .channels-header {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
    p {
      margin: 0;
    }
  }

  .channels-content {
    margin-right: 10px;
    text-align: center;
    font-weight: 300;
    font-size: 24px;
    margin: 0;
    p {
      margin: 0;
    }
    .api {
      margin-bottom: 20px;
    }
  }
}
