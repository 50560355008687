.suspension-container{
  padding: 16px 32px;

  height: 100%;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  .ant-row{
    height: 100%;
  }
  .jobs-counter{
    text-align: center;
    p {
      font-size: 32px;
      font-weight: 500;
      margin: 0;
    }
  }
  .form-container {
    text-align: center;
    Button {
      width: fit-content;
      height: fit-content;
      font-size: 24px;
      font-weight: 900;
    }
    label {
      margin: 0 12px;
    }
    Input {
      width: 90px;
    }
  }
}