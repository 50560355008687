body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// has no effect
// p {
//   margin: 0;
//   padding: 0;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-layout {
  
}

.search {
  padding: 1.6rem;
}

.user-filter {
  padding: 1.6rem;
  padding-top: 0;

  label {
    padding-right: 0.5rem;
  }
}

.header {
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  &-email {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    color: #787878;
  }
  &-link {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  &-leftside {
    flex: 1;
    display: flex;
    align-items: center;
    height: 4rem;
  }
}

.user-action {
  &-history {
    margin-bottom: 1.5rem;
  }
  &-subtitle {
     margin-left: .3rem;
     margin-bottom: .5rem;
     font-weight: 700;
     border-bottom: 1px solid #ededed;
     p {
      margin-bottom: .2rem;
     }
  }
  &-message {
    margin-left: 1rem;
    margin-right: 1rem;
    p {
      margin-bottom: 0;
    }
  }
  &-comment {
    margin: 2rem;
    margin-top: .6rem;
    margin-bottom: .6rem;
    padding: 1rem;
    padding-bottom: 1.1rem;
    border-radius: 3px;
    background-color: #bae2ff;
    p {
      margin: 0;
    }
  }
}

.user-details-container {
  display: flex;
  margin-bottom: 1.6rem;
}

.user-details {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: .5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  // justify-content: space-between;
  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: .3rem;
    margin-bottom: 1rem;
  }
  &-components {
    display: flex;
    flex-wrap: wrap;
  }
  &-info {
    padding-right: 1.6rem;
  }
  &-menu {
    min-height: calc(100vh - 4rem);
    width: 10rem;
    &-button {
      width: 100%;
      border-radius: 0;
      border: none;
      height: 3rem;
      outline: 0;
      margin-bottom: 0.3rem;
      cursor: pointer;
      background-color: #40dcff;
      &:disabled {
        cursor: not-allowed;
        background-color: #ededed;
        color: #5e5e5e;
      }
      &:hover {
        // background-color: aqua;
      }
    }
  }
  &-credits {
    min-width: 20rem;
    margin-right: 1rem;
    &-content {
      padding-left: .5rem;
      padding-right: .5rem;
      padding-top: .5rem;
      &-info {
        background-color: #e8e8e8;
        border-radius: 3px;
        padding: 1rem;
        margin-bottom: 1rem;
        p {
          margin: 0;
        }
      }
    }
  }
}

.red-bg {
  background-color: #fa2d67;
  color: #fff;
}

.font-red {
  color: #fa2d67;
}

.front-blue {
  color: #0da2ff;
}

.font {
  &-green {
    color: #2aeb1c;
  }
  &-error {
    color: #fa2d67;
  }
  &-success {
    color: #4feb28;
  }
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

.bold {
  font-weight: 700;
}

.action-modal-comment {
  // margin-top: 1.6rem;
}

.manage-credits {
  margin-bottom: 1.6rem;
  &-label {
    margin-top: 1.6rem;
  }
}

.card {
  &-identifiers {
    padding-left: 1rem;
    padding-bottom: .6rem;
    margin-bottom: .6rem;
    border-bottom: 1px solid #dedede;
  }
  &-field {
    &-highlight {
      padding-top: .2rem;
    }
  }
}

.subscription-details {
  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: .3rem;
  }
  &-content {
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;
    &-cancelled {
      margin-bottom: 1rem;
      background-color: #ffd982;
      border-radius: 3px;
      padding: 1rem;
      p {
        margin: 0;
      }
    }
    &-cycle {
      background-color: #e8e8e8;
      border-radius: 3px;
      padding: 1rem;
      margin-bottom: 1rem;
      &-header {
        margin-bottom: .5rem;
      }
      &-text {
        margin: 0;
        margin-left: 1rem;
        margin-bottom: .1rem;
      }
    }
    &-info {
      padding: .5rem;
      padding-top: 0;
    }
  }
}

.domains {
  margin: 1.6rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  &-message {
    display: flex;
    justify-content: center;
  }
  &-container {
    min-width: 20rem;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  &-header {
    margin-bottom: .8rem;
    text-align: center;
  }
  &-content {
    display: flex;
    flex-direction: column;

    &-button {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      min-width: 10rem;
      margin: 1rem;
    }
  }
}
