.ant-tabs-nav-wrap{
  padding-left: 1vw;
}
.tab-header{
  padding-left: 0.5vw;
}
.btn-position-right {
  float: right;
  margin-right: 0.5vw;
  margin-top: 0.5vh;
}