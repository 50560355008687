.event-actions-search{
  display: flex;
  flex-direction: row;
  button{
    margin-left: 10px;
  }
}

.event-action-list{
  .ant-list-item{
    display: flex;
    div{
      button{
        &:nth-child(2){
          margin-left: 10px;
        }
      }
    }
  }
}